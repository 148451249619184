import React, { useState } from 'react';
import { poseInstructions } from '../../utils/data';
import { poseImages } from '../../utils/pose_images';
import './Instructions.css';

export default function Instructions({ currentPose }) {
    const [instructions, setInstructions] = useState(poseInstructions);

    return (
        <div className="instructions-container d-flex flex-column align-items-center">
  <div className="w-100 mb-3">
    <h5 className="text-center">Instructions</h5>
    <ul className="instructions-list text-dark list-unstyled">
      {instructions[currentPose]?.map((instruction, index) => (
        <li key={index} className="instruction mb-2">{instruction}</li>
      ))}
    </ul>
  </div>
  
  <div className="pose-demo mb-3 d-flex justify-content-center">
    <img 
      className="pose-demo-img img-fluid" // img-fluid akan membuat gambar responsif
      src={poseImages[currentPose]}
      alt={`Pose demo for ${currentPose}`}
      style={{ maxWidth: '100%', height: 'auto' }} // Pastikan gambar tidak lebih lebar dari konten
    />
  </div>
</div>

    );
}
