import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validasi input
    if (!username || !password) {
      setError('Please enter your username and password');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`, 
        { username, password }
      );

      // Menyimpan token dari response backend
      // localStorage.setItem('token', response.data.success.token);
      navigate('/');
    } catch (error) {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-white mb-5">
      <div className="w-100 max-w-md bg-white p-4">
        <div className="text-center mb-4">
          <h2 className="font-weight-bold">Pose Gerakan Silat Berhasil!</h2>
          <p>Tanggal : 28-08-2024 06:40</p>
          <p>Gerakan : Jurus Tunggal 1</p>
          <p>Nilai Gerakan : 82.28 %</p>
          <p>Deskripsi Nilai Gerakan : Gerakan LEFT_SHOULDER(BAHU KIRI) anda tidak sesuai dengan gerakan Silat! </p>
          <p>Deskripsi Nilai Gerakan : Gerakan LEFT_ELBOW(SIKU KIRI) terlalu lurus! </p>
          <p>Deskripsi Nilai Gerakan : Gerakan RIGHT_KNEE(Lutut Kanan) tidak sesuai! </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
