import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './History.css';

const History = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fungsi untuk mengambil data riwayat dari API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/history`);
        setData(response.data.data);  // Mengambil 'data' dari struktur respons API
        setLoading(false);
      } catch (error) {
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="history-container">
      <h1 className="description">Riwayat Gerakan</h1>
      <div className="history-list">
        {data.map((item) => (
          <div key={item.id} className="history-item">
            <div className="history-date">{item.tgl}</div>
            <div className="history-details">
              <div className="history-move">Jurus Tunggal ID: {item.jurus_tunggal_id}</div>
              <div className="history-score">Presentase Kebenaran: {item.presentase_kebenaran}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
