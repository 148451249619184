import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faHistory, faUserCircle } from '@fortawesome/free-solid-svg-icons';

const BottomNavigation = () => {
  const location = useLocation();

  return (
    <div className="bottom-navigation">
      <Link to="/" className={`navigation-item ${location.pathname === '/' ? 'active' : ''}`}>
        <FontAwesomeIcon icon={faHome} />
        <span>Home</span>
      </Link>
      <Link to="/history" className={`navigation-item ${location.pathname === '/history' ? 'active' : ''}`}>
        <FontAwesomeIcon icon={faHistory} />
        <span>Hasil Latihan</span>
      </Link>
      <Link to="/profile" className={`navigation-item ${location.pathname === '/profile' ? 'active' : ''}`}>
        <FontAwesomeIcon icon={faUserCircle} />
        <span>Profil</span>
      </Link>
    </div>
  );
};

export default BottomNavigation;
