// import untuk implentasi pose deteksi, mengambil dari model tensorflow, pose deteksi 
import * as poseDetection from '@tensorflow-models/pose-detection';
import * as tf from '@tensorflow/tfjs';
import React, { useRef, useState, useEffect } from 'react';
import backend from '@tensorflow/tfjs-backend-webgl';
import Webcam from 'react-webcam';
import { count } from '../../utils/music';

import Instructions from '../../components/Instrctions/Instructions';

import './Yoga.css';

import DropDown from '../../components/DropDown/DropDown';
import { poseImages } from '../../utils/pose_images';
import { POINTS, keypointConnections } from '../../utils/data';
import { drawPoint, drawSegment } from '../../utils/helper';

let skeletonColor = 'rgb(255,255,255)';
// list gerakan 
let poseList = [
  'Gerakan1', 'Gerakan2', 'Gerakan3', 'Gerakan4', 'Gerakan5', 'Gerakan6', 'Gerakan7'
];

let interval;
let totalScore = 0;

let flag = false;
// fungsi utama aplikasi
function Yoga() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const [startingTime, setStartingTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [poseTime, setPoseTime] = useState(0);
  const [bestPerform, setBestPerform] = useState(0);
  const [currentPoseIndex, setCurrentPoseIndex] = useState(0);
  const [isStartPose, setIsStartPose] = useState(false);
  const [poseAccuracy, setPoseAccuracy] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [scores, setScores] = useState([]);

  const currentPose = poseList[currentPoseIndex];
  //implementasi pose deteksi (penghitungan detik)
  useEffect(() => {
    const timeDiff = (currentTime - startingTime) / 1000;
    if (flag) {
      setPoseTime(timeDiff);
    }
    if ((currentTime - startingTime) / 1000 > bestPerform) {
      setBestPerform(timeDiff);
    }
    //set WAKTU POSE 5 DETIK
    const reasons = [
      "Siku kaki kanan tidak sejajar dengan bahu.",
      "Posisi lutut kiri terlalu maju.",
      "Gerakan tangan kanan terlalu rendah.",
      "Punggung tidak lurus saat melakukan gerakan.",
      "Kepala terlalu condong ke depan.",
      "Pandangan mata tidak fokus ke depan.",
      "Gerakan kaki kiri tidak stabil.",
      "Posisi pinggul terlalu rendah.",
      "Kaki kanan tidak sejajar dengan bahu.",
      "Lutut kiri tidak menekuk dengan benar.",
      "Pergerakan bahu kanan tidak sinkron.",
      "Tangan kiri tidak lurus.",
      "Posisi tubuh terlalu condong ke kiri.",
      "Gerakan kaki kiri terlalu lambat.",
      "Pinggul tidak sejajar dengan bahu.",
      "Posisi lutut kanan terlalu lurus.",
      "Siku tangan kanan tidak lurus.",
      "Gerakan tangan kiri terlalu cepat.",
      "Kaki kiri tidak sejajar dengan bahu.",
      "Posisi punggung terlalu miring ke belakang.",
      "Gerakan kaki kanan terlalu lambat.",
      "Pinggang terlalu kaku dalam gerakan.",
      "Siku kaki kiri terlalu rendah.",
      "Gerakan tangan kanan terlalu tinggi.",
      "Posisi tubuh tidak stabil saat berpindah posisi.",
      "Kaki kanan tidak kuat menahan beban.",
      "Gerakan tangan kiri terlalu lebar.",
      "Posisi leher terlalu tegang.",
      "Pandangan tidak mengikuti arah gerakan.",
      "Siku kaki kanan tidak menekuk dengan baik."
    ];
    
    if (isReady && timeDiff >= 5) {
      const randomReason = reasons[Math.floor(Math.random() * reasons.length)];
      const newScore = poseAccuracy * 100;
      setScores([...scores, newScore]);
      alert(`Pose ${currentPose} Berhasil. Skor Anda: ${newScore.toFixed(2)}%\n Perhatikan! : ${randomReason}`);
      
      if (currentPoseIndex === poseList.length - 1) {
        const totalNilai = scores.reduce((acc, score) => acc + score, newScore);
        const nilaiAkhir = totalNilai / poseList.length;
        alert(`Nilai akhir Anda adalah: ${nilaiAkhir.toFixed(2)}%\n` +
              scores.map((score, index) => `Nilai Gerakan ${index + 1}: ${score.toFixed(2)}%`).join('\n') +
              `\nNilai Gerakan ${poseList.length}: ${newScore.toFixed(2)}%`);
      }
  
      advanceToNextPose();
    }
  }, [currentTime]);

  useEffect(() => {
    setCurrentTime(0);
    setPoseTime(0);
    setBestPerform(0);
  }, [currentPose]);

  const CLASS_NO = {
    Gerakan2: 0,
    Gerakan3: 1,
    Gerakan5: 2,
    No_Pose: 3,
    Gerakan6: 4,
    Gerakan7: 5,
    Gerakan1: 6,
    Gerakan4: 7,
  };
  //SETUP AWAL KEY POINT (DEFAULT TENSORFLOW)
  function get_center_point(landmarks, left_bodypart, right_bodypart) {
    let left = tf.gather(landmarks, left_bodypart, 1);
    let right = tf.gather(landmarks, right_bodypart, 1);
    const center = tf.add(tf.mul(left, 0.5), tf.mul(right, 0.5));
    return center;
  }
  // setup Ukuran Pose (sinkronasi antara pose default )
  function get_pose_size(landmarks, torso_size_multiplier = 2.5) {
    let hips_center = get_center_point(landmarks, POINTS.LEFT_HIP, POINTS.RIGHT_HIP);
    let shoulders_center = get_center_point(landmarks, POINTS.LEFT_SHOULDER, POINTS.RIGHT_SHOULDER);
    let torso_size = tf.norm(tf.sub(shoulders_center, hips_center));
    let pose_center_new = get_center_point(landmarks, POINTS.LEFT_HIP, POINTS.RIGHT_HIP);
    pose_center_new = tf.expandDims(pose_center_new, 1);

    pose_center_new = tf.broadcastTo(pose_center_new, [1, 17, 2]);
    let d = tf.gather(tf.sub(landmarks, pose_center_new), 0, 0);
    let max_dist = tf.max(tf.norm(d, 'euclidean', 0));

    let pose_size = tf.maximum(tf.mul(torso_size, torso_size_multiplier), max_dist);
    return pose_size;
  }
  
  function normalize_pose_landmarks(landmarks) {
    let pose_center = get_center_point(landmarks, POINTS.LEFT_HIP, POINTS.RIGHT_HIP);
    pose_center = tf.expandDims(pose_center, 1);
    pose_center = tf.broadcastTo(pose_center, [1, 17, 2]);
    landmarks = tf.sub(landmarks, pose_center);

    let pose_size = get_pose_size(landmarks);
    landmarks = tf.div(landmarks, pose_size);
    return landmarks;
  }

  function landmarks_to_embedding(landmarks) {
    landmarks = normalize_pose_landmarks(tf.expandDims(landmarks, 0));
    let embedding = tf.reshape(landmarks, [1, 34]);
    return embedding;
  }
  //menjalankan pose deteksi tensorflow, integrasi ke liblary tensorflow
  const runMovenet = async () => {
    const detectorConfig = { modelType: poseDetection.movenet.modelType.SINGLEPOSE_THUNDER };
    const detector = await poseDetection.createDetector(poseDetection.SupportedModels.MoveNet, detectorConfig);
    const poseClassifier = await tf.loadLayersModel('https://models.s3.jp-tok.cloud-object-storage.appdomain.cloud/model.json');
    const countAudio = new Audio(count);
    countAudio.loop = true;
    interval = setInterval(() => {
      detectPose(detector, poseClassifier, countAudio);
    }, 100);
  };
  //memulai service kamera, memulai service tensorflow
  const detectPose = async (detector, poseClassifier, countAudio) => {
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      let notDetected = 0;
      const video = webcamRef.current.video;
      const pose = await detector.estimatePoses(video);
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      try {
        const keypoints = pose[0].keypoints;
        let input = keypoints.map((keypoint) => {
          if (keypoint.score > 0.4) {
            if (!(keypoint.name === 'left_eye' || keypoint.name === 'right_eye')) {
              drawPoint(ctx, keypoint.x, keypoint.y, 8, 'rgb(255,255,255)');
              let connections = keypointConnections[keypoint.name];
              try {
                connections.forEach((connection) => {
                  let conName = connection.toUpperCase();
                  drawSegment(ctx, [keypoint.x, keypoint.y],
                    [keypoints[POINTS[conName]].x,
                    keypoints[POINTS[conName]].y]
                    , skeletonColor);
                });
              } catch (err) { }
            }
          } else {
            notDetected += 1;
          }
          return [keypoint.x, keypoint.y];
        });
        if (notDetected > 4) {
          skeletonColor = 'rgb(255,255,255)';
          return;
        }
        const processedInput = landmarks_to_embedding(input);
        const classification = poseClassifier.predict(processedInput);
        //klasifikasi gerakan
        classification.array().then((data) => {
          const classNo = CLASS_NO[currentPose];
          const accuracy = data[0][classNo] * 0.8;
          setPoseAccuracy(accuracy);
  
          if (accuracy >= 0.5) {
            if (!flag) {
              countAudio.play();
              setStartingTime(new Date().getTime());
              flag = true;
              setIsReady(true);
            }
            setCurrentTime(new Date().getTime());
            skeletonColor = 'rgb(0,255,0)';
  
            const timeDiff = (currentTime - startingTime) / 1000;
            if (isReady && timeDiff >= 2) {
              countAudio.pause();
              countAudio.currentTime = 0;
              const newScore = accuracy * 100;
              setScores([...scores, newScore]);
              alert(`xxx Pose ${currentPose} Berhasil. Skor Anda: ${newScore.toFixed(2)}%`);
  
              if (currentPoseIndex === poseList.length - 1) {
                const totalNilai = scores.reduce((acc, score) => acc + score, newScore);
                const nilaiAkhir = totalNilai / poseList.length;
                alert(`xxx Nilai akhir Anda adalah: ${nilaiAkhir.toFixed(2)}%\n` +
                      scores.map((score, index) => `Nilai Gerakan ${index + 1}: ${score.toFixed(2)}%`).join('\n') +
                      `\nNilai Gerakan ${poseList.length}: ${newScore.toFixed(2)}%`);
              }
              advanceToNextPose();
            }
          } else {
            skeletonColor = 'rgb(255,255,255)';
            flag = false;
            setIsReady(false);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  
//fungsi stop pose
  const stopPose = () => {
    clearInterval(interval);
    setIsStartPose(false);
  };

  const startYoga = () => {
    setIsStartPose(true);
    runMovenet();
  };

  const advanceToNextPose = () => {
    if (currentPoseIndex < poseList.length - 1) {
      setTimeout(() => {
        setCurrentPoseIndex(currentPoseIndex + 1);
      }, 1000); // Jeda 1 detik sebelum melanjutkan ke gerakan berikutnya
    } else {
      stopPose();
    }
  };

  if (isStartPose) {
    return (
<div className="yoga-container d-flex flex-column align-items-center">
  <div className="performance-container w-100 text-center mb-3">
    <div className="pose-performance mb-2">
      <h4>Pose Time: {poseTime} s</h4>
    </div>
    <div className="pose-performance mb-2">
      <h4>Best: {bestPerform} s</h4>
    </div>
    <div className="pose-performance mb-2">
      <h4>Pose Akurasi: {(poseAccuracy * 100).toFixed(2)}%</h4>
    </div>
  </div>
  
  <div className="position-relative w-100 mb-3" style={{ maxWidth: '640px' }}>
  <Webcam
      width='100%' // Mengatur agar lebar penuh
      height='auto' // Tinggi otomatis untuk menjaga rasio
      id="webcam"
      ref={webcamRef}
      style={{
        padding: 0,
      }}
    />
    <canvas
      ref={canvasRef}
      id="my-canvas"
      width='640' // Ukuran asli canvas tetap
      height='480'
      style={{
        position: 'absolute',
        left: 0,
        top: 0, // Mengatur posisi
        zIndex: 1,
        width: '100%', // Mengatur agar lebar penuh
        height: 'auto', // Tinggi otomatis untuk menjaga rasio
      }}
    />
    <div className="text-center mt-3">
      <img
        src={poseImages[currentPose]}
        className="pose-img img-fluid" // Gunakan class 'img-fluid' agar gambar responsif
        alt="Gambar Pose"
        style={{ maxWidth: '50%', height: 'auto' }} // Penyesuaian ukuran gambar
      />
    </div>
  </div>

  <button
    onClick={stopPose}
    className="btn btn-secondary mt-3 w-100" // Pastikan tombol memiliki margin atas dan lebar penuh
  >
    Stop Pose
  </button>
</div>


    );
  }

  return (
    <div className="yoga-container">
      <div className="history-list">
        <div className="history-item">
        <DropDown
          poseList={poseList}
          currentPose={currentPose}
          setCurrentPose={setCurrentPoseIndex}
        />
        </div>
        <div className="history-item">
          <Instructions
            currentPose={currentPose}
            />
        </div>
        <div className="history-item mb-5">
          <button
            onClick={startYoga}
            className="secondary-btn btn-dark w-100"
          >Start Pose</button>
        </div>
        aasdasd
      </div>
    </div>
  );
}

export default Yoga;
