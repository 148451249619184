export const poseInstructions = {
    Gerakan1: [
        'Gerakan 1: Pasang Awal',
        'Sikap Pasang Kaki Kanan Depan: Berdiri dengan kaki kanan di depan, sedikit lebih lebar dari lebar bahu. Kaki kiri di belakang, jari kaki mengarah sedikit ke dalam. Jaga lutut sedikit ditekuk dan berat badan seimbang di kedua kaki.',
        'Selup Tangan Kanan: Angkat tangan kanan ke dada, telapak tangan menghadap ke dalam. Jari-jari rileks dan sedikit renggang.',
    ],
    Gerakan3: [
        'Gerakan 3: Dobrak',
        'Dobrak: Luruskan kedua lengan ke depan, telapak tangan menghadap ke bawah, seolah-olah Anda akan mendorong membuka pintu. Lengan harus lurus dan jari-jari harus renggang.',
    ],
    Gerakan5: [
        'Gerakan 5: Angkat Lutut Kiri dan Patah',
        'Angkat Lutut Kiri: Angkat lutut kiri ke dada, jaga paha sejajar dengan tanah. Kaki bagian bawah tegak lurus dengan tanah, dan kaki ditekuk.',
        'Patah dengan Dua Tangan: Bersamaan dengan mengangkat lutut kiri, lakukan gerakan "patah" dengan kedua tangan. Turunkan tangan kanan ke paha kanan, telapak tangan menghadap ke dalam, dan angkat tangan kiri ke bahu kiri, telapak tangan menghadap ke bawah. Lengan harus ditekuk di siku.',
    ],
    Gerakan2: [
        'Gerakan 2: Tepuk Sisir dan Naik Lutut Kanan',
        'Tepuk Sisir: Lakukan gerakan "tepak sisir" dengan tangan kanan. Dimulai dengan tangan di depan dada, kemudian putar pergelangan tangan ke depan dengan cepat, luruskan lengan sepenuhnya. Jari-jari rapat dan telapak tangan menghadap ke luar.',
        'Lutut Kanan Naik Tekuk 90 Derajat: Bersamaan dengan gerakan "tepak sisir", angkat lutut kanan ke atas hingga sudut 90 derajat, jaga paha sejajar dengan tanah. Kaki bagian bawah tegak lurus dengan tanah, dan kaki ditekuk.',
    ],
    Gerakan4: [
        'Gerakan 4: Tangkap, Tarik, dan Pandangan',
        'Tangkap: Bayangkan Anda telah menangkap benda dengan tangan kiri. Angkat tangan kiri ke dada, telapak tangan menghadap ke dalam.',
        'Tarik: Tarik lengan kiri ke belakang, jaga siku dekat dengan tubuh. Tangan harus berakhir di dekat bahu kiri.',
        'Simpan ke Arah Rusuk: Turunkan tangan kiri ke sisi kiri, letakkan di atas rusuk.',
        'Pandangan: Putar kepala dan lihat ke arah tangan kiri, ikuti gerakannya.',
    ],
    Gerakan7: [
        'Gerakan 7: Pukul Depan Kanan dan Selop',
        'Pukul Depan Kanan: Lakukan pukulan dengan tangan kanan, luruskan lengan ke depan dan putar pinggul. Kepalan tangan harus tertutup dan buku jari menghadap ke atas.',
        'Selop ke Arah Kiri: Bawa tangan kanan kembali ke bahu kiri, telapak tangan menghadap ke bawah. Lengan harus ditekuk di siku.',
    ],
    Gerakan6: [
        'Gerakan 6: Tendang Lurus Kanan',
        'Tendang Lurus Kanan: Luruskan kaki kanan ke depan, menendang dengan tumit. Bidik target imajiner di depan Anda. Kaki harus lurus, dan jari kaki harus mengarah ke atas.',
        'Tangan Lurus ke Arah Bawah: Turunkan tangan kanan ke arah tanah, telapak tangan menghadap ke dalam. Lengan harus lurus.',
        'Tangan Kiri Menutup Dada: Bawa tangan kiri ke dada, telapak tangan menghadap ke dalam. Jari-jari rileks dan sedikit renggang.',
    ]

}


export const tutorials = [
    '1. When App ask for permission of camera, allow it to access to capture pose.',
    '2. Select what pose you want to do in the dropdown.',
    '3. Read Instrctions of that pose so you will know how to do that pose.',
    '4. Click on Start pose and see the image of the that pose in the right side and replecate that image in front of camera.',
    '5. If you will do correctly the skeleton over the video will become green in color and sound will start playing'
]

export const fixCamera = [
    'Solution 1. Make sure you have allowed the permission of camera, if you have denined the permission, go to setting of your browser to allow the access of camera to the application.',
    'Solution 2. Make sure no any other application is not accessing camera at that time, if yes, close that application',
    'Solution 3. Try to close all the other opened broswers'
]

export const POINTS = {
    NOSE: 0,
    LEFT_EYE: 1,
    RIGHT_EYE: 2,
    LEFT_EAR: 3,
    RIGHT_EAR: 4,
    LEFT_SHOULDER: 5,
    RIGHT_SHOULDER: 6,
    LEFT_ELBOW: 7,
    RIGHT_ELBOW: 8,
    LEFT_WRIST: 9,
    RIGHT_WRIST: 10,
    LEFT_HIP: 11,
    RIGHT_HIP: 12,
    LEFT_KNEE: 13,
    RIGHT_KNEE: 14,
    LEFT_ANKLE: 15,
    RIGHT_ANKLE: 16,
}

export const keypointConnections = {
    nose: ['left_ear', 'right_ear'],
    left_ear: ['left_shoulder'],
    right_ear: ['right_shoulder'],
    left_shoulder: ['right_shoulder', 'left_elbow', 'left_hip'],
    right_shoulder: ['right_elbow', 'right_hip'],
    left_elbow: ['left_wrist'],
    right_elbow: ['right_wrist'],
    left_hip: ['left_knee', 'right_hip'],
    right_hip: ['right_knee'],
    left_knee: ['left_ankle'],
    right_knee: ['right_ankle']
}