import React from 'react';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';

export default function Home() {
    return (
        <div className='home-container text-center bg-dark'>
            <link 
                href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" 
                rel="stylesheet" 
                integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" 
                crossorigin="anonymous">
            </link>
            
            <h1 className="description text-white mt-3">Evaluasi Kebenaran Jurus Tunggal</h1>
            <p className="text-white">selamat datang, Ilyas</p>
            
            <div className="home-main d-flex align-items-center justify-content-center text-center">
                <div className="btn-section mt-5">
                    <Link to='/start' className="d-block mb-3">
                        <button className="btn btn-primary">Jurus 1</button>
                    </Link>
                    <Link to='/start' className="d-block mb-3">
                        <button className="btn btn-primary">Jurus 2</button>
                    </Link>
                    {/* <Link to='/tutorials' className="d-block">
                        <button className="btn btn-secondary">Tutorials</button>
                    </Link> */}
                </div>
            </div>
        </div>
    );
}
