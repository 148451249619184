import two from './1_2.jpg'
import three from './1_3.jpg'
import five from './1_5.jpg'
import one from './1_1.jpg'
import four from './1_4.jpg'
import seven from './1_7.jpg'
import six from './1_6.jpg'

export const poseImages = {
    Gerakan1: one,
    Gerakan3: three,
    Gerakan5: five,
    Gerakan4: four,
    Gerakan2: two,
    Gerakan7: seven,
    Gerakan6: six
} 



 