import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Profile.css';
const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: 'Ilyas',
    address: 'Jl Jeruk no 23, Bandung',
    ktp: '327322100000000',
    phone: '081221200000',
    joinDate: '2024-07-01',
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get('/api/profile');
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1 className="profile-name">{profileData.name}</h1>
        <p className="profile-join-date">Bergabung sejak {profileData.joinDate}</p>
      </div>
      <div className="profile-details">
        <div className="profile-item">
          <i className="fas fa-map-marker-alt"></i>
          <span className="profile-label">Alamat:</span>
          <span className="profile-value">{profileData.address}</span>
        </div>
        <div className="profile-item">
          <i className="fas fa-id-card"></i>
          <span className="profile-label">No. KTP:</span>
          <span className="profile-value">{profileData.ktp}</span>
        </div>
        <div className="profile-item">
          <i className="fas fa-phone"></i>
          <span className="profile-label">No. HP:</span>
          <span className="profile-value">{profileData.phone}</span>
        </div>
      </div>
    </div>
  );
};

export default Profile;
