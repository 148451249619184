import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Yoga from './pages/Yoga/Yoga';
import History from './pages/History/History';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';
import Success from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import BottomNavigation from './components/BottomNavigation';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Success" element={<Success />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/start" element={<Yoga />} />
          <Route path="/history" element={<History />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
        <BottomNavigation />
      </div>
    </Router>
  );
}

export default App;
